<template>
  <div v-if=" scenicList.extra && scenicList.extra.length>1 && scenicList.extra[1].title != '__mediabank_adv__'">
    <div class="detailsPartDiv " v-for="(item,index) in scenicList.extra.slice(1,2)" :key="index">
      <div class="distTitle1">{{ $t('app.introduction') }}</div>

      <div class="distDiv">

        <div class="distItem" v-for="(item1,index1) in scenicList" :key="index1" @click="toDetail(item1)">
        
          <div class="imgDiv">
            <img :src="urlSpilt(item1.img)" class="img" alt="">
            <div class="titleDiv">
              <div class="title">{{ item1.name }}
              </div>
              <div class="describe">{{ item1.title }}
              </div>
            </div>


          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "detailsPart1",
  props: ["scenicList"],
  data() {
    return {
      value1: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),
      option1: [
        {text: this.$t('app.en'), value: 'en'},
        {text: this.$t('app.jp'), value: 'jp'},
        {text: this.$t('app.cn'), value: 'cn'},
        {text: this.$t('app.hk'), value: 'hk'},
      ],
    }
  },
  computed: {
    urlSpilt() {
      return (url) => {
        return url.split(",")[0];
      };
    }
  },
  methods: {
    toDetail(item) {
      const i = item
      this.$router.push({
        path: "/detailsPage",
        query: {
          type: i.articleType,
          id: i.articleId
        }
      });
    }

  },

}
</script>

<style scoped lang="less">
.detailsPartDiv {
  padding: 0 0 0.27rem;
  text-align: start;
  background: white;

  .distTitle1 {
    color: black;
    font-size: .37rem;
    font-weight: 600;
    margin: 0 0.4rem 0.27rem 0.4rem;
  }

  .distTitle2 {
    color: #707070;
    font-size: 0.3rem;
    margin: 0.1rem 0.4rem 0.4rem 0.4rem;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    margin: 0.17rem;
    width: 4.24rem;
    height: 4.64rem;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    &:first-child {
      margin-left: 0.4rem;
    }

    &:last-child {
      margin-right: 0.4rem;
    }


    .imgDiv {
      position: relative;
      width: 4.24rem;

      .titleDiv {
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        width: 4.24rem;
        color: white;
        font-size: 0.4rem;
        text-align: start;
        padding: 0.15rem 0.2rem;
        box-sizing: border-box;
      }

      .title {
        font-size: .32rem;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .describe {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.3rem;


      }

      .img {
        display: flex;
        width: 4.24rem;
        height: 4.64rem;
        object-fit: cover;
      }
    }


  }


}

</style>

<template>
  <div v-if="scenicList.extra && scenicList.extra.length>4">
    <div class="detailsPartDiv">
    <div class="distTitle1">{{ dataList.title }}</div>
   

    <!-- <div class="distDiv">
      <div class="distItem">
        <div class="imgDiv" v-for="(item,index) in scenicList.extra[4].list" :key="index" @click="toDetail(item)">
          <img :src="item.img" class="img" alt="" >
          <div class="titleDiv">
            <div class="title">{{ item.name }}
            </div>
            <div class="describe">{{ item.desc | filtersText }}
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <el-carousel :autoplay="false">
        <el-carousel-item v-for="(item, index) in dataList.list" :key="index">
          <div class="distDiv" >
            <div class="distItem" >
              <div class="imgDiv" v-for="(item1, index1) in item" :key="index1" @click="toDetail(item1)">
                <img :src="urlSpilt(item1.img)" class="img" alt="">
                <div class="titleDiv">
                  <div class="title">{{ item1.name }}
                  </div>
                  <div class="describe">{{ item1.desc | filtersText}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>


  </div>
  </div>
  
</template>

<script>


export default {
  name: "detailsPart3",
  props: ["scenicList"],
  data() {
    return {
      value1: localStorage.getItem('language') == null ? 'en' : localStorage.getItem('language'),
      option1: [
        { text: this.$t('app.en'), value: 'en' },
        { text: this.$t('app.jp'), value: 'jp' },
        { text: this.$t('app.cn'), value: 'cn' },
        { text: this.$t('app.hk'), value: 'hk' },
      ],
      dataList: [],
    }
  },
  computed: {
    urlSpilt() {
      return (url) => {
        return url.split(",")[0];
      };
    }
  },
    watch: {
      scenicList: {
        handler(val) {
          if(val.extra && val.extra.length>4) {
            const temList = [];
            val.extra[4].list.forEach((v, i) => {
              const index = Math.floor(i / 3);
              if(!temList[index]) {
                temList[index] = [];
              }
              temList[index].push(v);
            });
            val.extra[4].list = temList;
            this.dataList = val.extra[4];
          }
        }
      }
  },
  filters: {
        getFristText(str) {
            let rText = str.substring(str.indexOf('<p'), str.lastIndexOf('</p'));
            return rText.replace(/<[^>]+>/g, '').trim()
        },
        filtersText(val) {
            if (val != null && val != '') {
                let reg = new RegExp("<.+?>", "g");
                let arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"', 'ldquo': '“', 'mdash': '—', "rdquo": '”' };
                return val.replace(reg, '').replace(/&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/ig, function (all, t) {
                    return arrEntities[t];
                });
                // } else return '';
                // if (val != null && val != '') {
                //     let reg = /<img.*?(?:>|\/>)/gi;
                //     let names = val.match(val.replaceAll(/<[^>]+>/g,""));
                //     val = names ? names.join('') : '';
                //     return val;
                // } else return '';

            }
        }
    },
  methods: {
    toDetail(item) {
      const path = item.articleType == '5' ? '/detailsPage' : item.articleType == '6' ? '/orderXq' : '/essayDetail';
      this.$router.push({
        path: path,
        query: {
          type: item.articleType,
          id: item.articleId
        }
      });
    }

  },

}
</script>

<style scoped lang="less">
.detailsPartDiv {
  text-align: start;
  background: white;
  width: 1136px;
  margin: 80px auto 0;

  .distTitle1 {
    color: black;
    font-size: .3rem;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .distTitle2 {
    color: #707070;
    font-size: 0.3rem;
    margin: 0.1rem 0.4rem 0.4rem 0.4rem;
  }

  .distDiv {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .distItem {
    display: inline-flex;
    background: transparent;
    width: 368px;
    height: 328px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    // &:first-child {
    //   margin-left: 0.4rem;
    // }

    // &:last-child {
    //   margin-right: 0.4rem;
    // }


    .imgDiv {
      position: relative;
      width: 368px;
      margin-right: 16px;
      cursor: grab;
      .titleDiv {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        width: 368px;
        color: white;
        font-size: 0.25rem;
        text-align: start;
        padding: 0.1rem 0.1rem;
        box-sizing: border-box;
      }

      .title {
        font-size: .25rem;
        font-weight: 600;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .describe {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.2rem;
        height: 25px;
        line-height: 25px;
      }

      .img {
        display: flex;
        width: 368px;
        height: 328px;
        object-fit: cover;
        border-radius: 8px;
      }
    }


  }


}
.title{
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  font-size: 18px;
}
/deep/ .el-carousel__container {
  position: relative;
  height: 330px;
  white-space: nowrap;
}
/deep/ .el-carousel__indicators--horizontal {
  display: none;
}
</style>

<template>

  <div v-if=" adv && adv.list && adv.list.length > 0"
     class="detailsPart1Pic"
     :style="{'background-image':'url('+adv.list[0].img+')'}">
    <div class="textDiv">
      <div class="adTitle">{{adv.list[0].name}}</div>
      <!-- <div class="subTitle">We’ve got everything you need to travel Japan in 2023</div> -->
      <button class="explore" style="cursor: pointer" @click="toDetail(adv.list[0])">{{$t('app.exploreNow')}}</button>
    </div>
  </div>

</template>

<script>

export default {
  name: "detailsPart1Pic",
  props: ["adv"],

  data() {
    return {
      adImg: 'https://bing.com/th?id=OSGI.C9E7A1A2E0E14D97DA9392417D14BC19&h=1000&w=750&c=1&rs=1&o=3',
    }
  },

  methods:{
    toDetail(item) {
      if (item.refType == '2') {
        window.open(item.outLink);
      } else {
        let url = "/essayDetail"
        if (item.articleType == '6') {
          url = "/orderXq"
        } else if (item.articleType == '5') {
          url = "/detailsPage"
        }
        // console.log(item)
        this.$router.push({
          path: url,
          query: {
            type: item.articleType,
            id: item.articleId
          }
        });
      }
    }
  }


}
</script>

<style scoped lang="less">
.detailsPart1Pic {
  margin-bottom: 0.44rem;
  margin-top: 80px;
  width: 1136px;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  display: inline-flex;
  flex-direction: column;
  position: relative;

  .textDiv {
    text-align: left;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    position: absolute;
    bottom: 0.2rem;

    .adTitle {
      font-size: 0.3rem;
      font-weight: 600;
    }

    .subTitle {
      font-size: 0.2rem;
      line-height: 0.5rem;
      margin-top: 0.1rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .explore {
      width: 1.5rem;
      height: 0.5rem;
      font-size: 0.16rem;
      background: white;
      color: black;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.1rem;
      border: none;
    }

  }


}

</style>
